@font-face {
  font-weight: 300;
  font-family: "Helvetica Now Display";
  font-style: normal;
  src: url("helvetica/HelveticaNowDisplay-Light.eot");
  src: local("Helvetica Now Display Light"), local("HelveticaNowDisplay-Light"),
    url("helvetica/HelveticaNowDisplay-Light.eot?#iefix") format("embedded-opentype"),
    url("helvetica/HelveticaNowDisplay-Light.woff2") format("woff2"), url("helvetica/HelveticaNowDisplay-Light.woff") format("woff"),
    url("helvetica/HelveticaNowDisplay-Light.ttf") format("truetype");
}

@font-face {
  font-weight: normal;
  font-family: "Helvetica Now Display";
  font-style: normal;
  src: url("helvetica/HelveticaNowDisplay-Regular.eot");
  src: local("Helvetica Now Display Regular"), local("HelveticaNowDisplay-Regular"),
    url("helvetica/HelveticaNowDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("helvetica/HelveticaNowDisplay-Regular.woff2") format("woff2"), url("helvetica/HelveticaNowDisplay-Regular.woff") format("woff"),
    url("helvetica/HelveticaNowDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-weight: 500;
  font-family: "Helvetica Now Display";
  font-style: normal;
  src: url("helvetica/HelveticaNowDisplay-Medium.eot");
  src: local("Helvetica Now Display Medium"), local("HelveticaNowDisplay-Medium"),
    url("helvetica/HelveticaNowDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("helvetica/HelveticaNowDisplay-Medium.woff2") format("woff2"), url("helvetica/HelveticaNowDisplay-Medium.woff") format("woff"),
    url("helvetica/HelveticaNowDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-weight: bold;
  font-family: "Helvetica Now Display";
  font-style: normal;
  src: url("helvetica/HelveticaNowDisplay-Bold.eot");
  src: local("Helvetica Now Display Bold"), local("HelveticaNowDisplay-Bold"),
    url("helvetica/HelveticaNowDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("helvetica/HelveticaNowDisplay-Bold.woff2") format("woff2"), url("helvetica/HelveticaNowDisplay-Bold.woff") format("woff"),
    url("helvetica/HelveticaNowDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-weight: 800;
  font-family: "Helvetica Now Display";
  font-style: normal;
  src: url("helvetica/HelveticaNowDisplay-ExtraBold.eot");
  src: local("Helvetica Now Display ExtBd"), local("HelveticaNowDisplay-ExtraBold"),
    url("helvetica/HelveticaNowDisplay-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("helvetica/HelveticaNowDisplay-ExtraBold.woff2") format("woff2"), url("helvetica/HelveticaNowDisplay-ExtraBold.woff") format("woff"),
    url("helvetica/HelveticaNowDisplay-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-weight: 300;
  font-family: "Lato";
  font-style: normal;
  src: url("lato/Lato-Light.eot");
  src: local("Lato Light"), local("Lato-Light"), url("lato/Lato-Light.eot?#iefix") format("embedded-opentype"),
    url("lato/Lato-Light.woff2") format("woff2"), url("lato/Lato-Light.woff") format("woff"), url("lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-weight: normal;
  font-family: "Lato";
  font-style: normal;
  src: url("lato/Lato-Regular.eot");
  src: local("Lato Regular"), local("Lato-Regular"), url("lato/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("lato/Lato-Regular.woff2") format("woff2"), url("lato/Lato-Regular.woff") format("woff"),
    url("lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-weight: 500;
  font-family: "Lato";
  font-style: normal;
  src: url("lato/Lato-Medium.eot");
  src: local("Lato Medium"), local("Lato-Medium"), url("lato/Lato-Medium.eot?#iefix") format("embedded-opentype"),
    url("lato/Lato-Medium.woff2") format("woff2"), url("lato/Lato-Medium.woff") format("woff"),
    url("lato/Lato-Medium.ttf") format("truetype");
}

@font-face {
  font-weight: bold;
  font-family: "Lato";
  font-style: normal;
  src: url("lato/Lato-Bold.eot");
  src: local("Lato Bold"), local("Lato-Bold"), url("lato/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    url("lato/Lato-Bold.woff2") format("woff2"), url("lato/Lato-Bold.woff") format("woff"), url("lato/Lato-Bold.ttf") format("truetype");
}
